import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import router from "./router.js";
import Meta from "vue-meta";
import vshare from "vshare";
import "./util/rem.js";
import "./assets/css/reset.css";
import echarts from "echarts";
Vue.prototype.$echarts = echarts;
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
Vue.use(VueRouter);
Vue.use(Meta);
Vue.use(vshare);
Vue.config.productionTip = false;


router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  // console.log(to.meta.content)
  if (to.meta.content) {
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  // /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next()
});

//设置该时间内重复触发只执行一次，单位ms
Vue.prototype.$canSubmit = true
let timer = null
Vue.prototype.$preventRepeatSubmit = (time = 500, fn) => {
  if (Vue.prototype.$canSubmit) {
    clearTimeout(timer)
    Vue.prototype.$canSubmit = false
    if (fn) fn()
    timer = setTimeout(() => {
      Vue.prototype.$canSubmit = true
    }, time)
  }
}
// vue页面内用法：
// if (!this.$canSubmit) {
//   this.$alert("请勿重复提交", "提示", {
//     confirmButtonText: "确定",
//   })
//   return
// }
// this.$preventRepeatSubmit()

new Vue({
  render: (h) => h(App),
  router,
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
}).$mount("#app");
