<template>
  <div id="app">
    <transition :name="transitionName">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      transitionName: "",
      menu: 1,
    };
  },
  methods: {
    switcha() {
      let type = this.menu;
      if (type == 1) {
        this.menu = 2;
      } else {
        this.menu = 1;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      var head = document.head || document.getElementsByTagName('head')[0];
      var jquery = document.createElement('script');
      jquery.setAttribute("src", 'https://probe.bjmantis.net/chat/jquery-1.12.4.min.js');
      head.insertBefore(jquery, head.childNodes[0]);
      jquery.onload = () => {
        var bjmantis = document.createElement('script');
        bjmantis.setAttribute("src", 'https://pg-chatn8.bjmantis.net/chat/js/dist/mantis.min.js?6027#63198938c333160001ba3674');
        head.insertBefore(bjmantis, head.childNodes[1]);
      }
    }, 5000)
  },
  watch: {
    // 使用watch 监听$router的变化
    $route(to, from) {
      if (to.path.indexOf("jiyun_") >= 0 || from.path.indexOf("jiyun_") >= 0) {
        // 如果to索引大于from索引,判断为前进状态,反之则为后退状态
        if (to.meta.index && to.meta.index > from.meta.index) {
          // 设置动画名称
          this.transitionName = "slide-bottom";
        }
        if (to.meta.index && to.meta.index < from.meta.index) {
          this.transitionName = "slide-top";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
html {
  font-size: 100px;
}

#app,
.box {
  width: 100%;
  font-family: MicrosoftYaHei;
}

.slide-bottom-enter-active,
.slide-bottom-leave-active,
.slide-top-enter-active,
.slide-top-leave-active {
  will-change: transform;
  transition: all 500ms;
  position: absolute;
}

.slide-bottom-enter {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}

.slide-bottom-leave-active {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}

.slide-top-enter {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}

.slide-top-leave-active {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}

.rightNav {
  width: 210px;
  height: 520px;
  position: fixed;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 20;
  cursor: pointer;
  transition: 0.5s linear;
}

.rightNavChange {
  right: -170px;
  transition: 0.5s linear;
}

.left-on-line {
  width: 40px;
  height: 130px;
  background: url("~@/assets/images/left-bg.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

.left-on-line-change {
  background: url("~@/assets/images/zhankaibj.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

.right-box {
  width: 170px;
  height: 100%;
  background: url("~@/assets/images/right-bg.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  display: flex;
  justify-content: center;
}

.left-on-line img {
  width: 100%;
  height: 100%;
}

.right-box ul {
  margin-top: 42px;
}

.right-box ul li {
  margin-bottom: 8px;
  position: relative;
}

.right-box ul li:nth-child(2):hover div {
  display: block;
}

.right-box ul li:nth-child(2)>div {
  width: 150px;
  height: 150px;
  position: absolute;
  left: -150px;
  top: -40px;
  display: none;
}

.right-box ul li:nth-child(2)>div img {
  width: 100%;
  height: 100%;
}

.right-box ul li a {
  display: inline-block;
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
}

.right-box ul li,
.right-box ul li>img {
  width: 150px;
  height: 52px;
}
</style>
